import CheckboxComponent from '@wix/thunderbolt-elements/src/components/Checkbox/viewer/Checkbox';
import CheckboxController from '@wix/thunderbolt-elements/src/components/Checkbox/viewer/Checkbox.controller';


const Checkbox = {
  component: CheckboxComponent,
  controller: CheckboxController
};


export const components = {
  ['Checkbox']: Checkbox
};

