import * as React from 'react';
import classNames from 'clsx';
import {
  customCssClasses,
  getAriaAttributes,
  getDataAttributes,
  useAnalyticsReportClicks,
} from '@wix/editor-elements-common-utils';
import { ICheckboxProps, ICheckboxImperativeActions } from '../Checkbox.types';
import Link from '../../Link/viewer/Link';
import semanticClassNames from '../Checkbox.semanticClassNames';
import style from './style/CheckboxBasicSkin.scss';
import Checkmark from './assets/checkmark';

const noop = () => {};

const Checkbox: React.ForwardRefRenderFunction<
  ICheckboxImperativeActions,
  ICheckboxProps
> = (props, ref) => {
  const {
    id,
    checked = false,
    linkLabel = '',
    link = {},
    label,
    value,
    required,
    isDisabled,
    readOnly = false,
    shouldShowValidityIndication,
    validateValueAndShowIndication = noop,
    setValidityIndication = noop,
    onBlur = noop,
    onFocus = noop,
    onChange = noop,
    onCheckedChange = noop,
    onClick = noop,
    onDblClick = noop,
    onMouseEnter = noop,
    onMouseLeave = noop,
    reportBiOnClick = noop,
    reportBiOnLinkClick = noop,
    name,
    ariaAttributes,
    className,
    customClassNames = [],
  } = props;

  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useImperativeHandle(ref, () => {
    return {
      focus: () => {
        inputRef.current?.focus();
      },
      blur: () => {
        inputRef.current?.blur();
      },
      setCustomValidity: message => {
        if (message.type === 'message') {
          inputRef.current?.setCustomValidity(message.message);
        }
      },
      get isFocused() {
        return (
          !!inputRef.current && inputRef.current === document.activeElement
        );
      },
      get isChecked() {
        return !!inputRef.current && inputRef.current.checked;
      },
    };
  });

  const _onChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    if (readOnly) {
      return;
    }
    onCheckedChange(event.target.checked);
    validateValueAndShowIndication();
    onChange(event);
  };

  const _onBlur: React.FocusEventHandler<HTMLInputElement> = event => {
    setValidityIndication(true);
    onBlur(event);
  };

  const _onClick = useAnalyticsReportClicks({
    reportBiOnClick,
    onClick: isDisabled ? undefined : onClick,
  });

  const _onDblClick: React.MouseEventHandler<HTMLLabelElement> = event => {
    if (!isDisabled) {
      onDblClick(event);
    }
  };

  const _onMouseEnter: React.MouseEventHandler<HTMLLabelElement> = event => {
    if (!isDisabled) {
      onMouseEnter(event);
    }
  };

  const _onMouseLeave: React.MouseEventHandler<HTMLLabelElement> = event => {
    if (!isDisabled) {
      onMouseLeave(event);
    }
  };

  const containerClasses = classNames(
    style.CheckboxBasicSkin,
    className,
    customCssClasses(semanticClassNames.root, ...customClassNames),
    {
      [style.disabled]: isDisabled,
      [style.validationIndication]: !!shouldShowValidityIndication,
    },
  );

  return (
    <label
      id={id}
      {...getDataAttributes(props)}
      data-testid="checkbox"
      className={containerClasses}
      onDoubleClick={_onDblClick}
      onMouseEnter={_onMouseEnter}
      onMouseLeave={_onMouseLeave}
    >
      <input
        data-testid="input"
        onClick={_onClick}
        ref={inputRef}
        type="checkbox"
        className={style.input}
        checked={checked}
        value={value}
        onChange={_onChange}
        onBlur={_onBlur}
        onFocus={onFocus}
        required={required}
        disabled={isDisabled}
        name={name}
        {...getAriaAttributes(ariaAttributes)}
      />
      <span
        className={classNames(
          style.checkbox,
          customCssClasses(semanticClassNames.input),
        )}
      ></span>
      <div className={style.checkmark}>
        <Checkmark />
      </div>
      <div
        className={classNames(
          style.label,
          customCssClasses(semanticClassNames.label),
        )}
      >
        <span data-testid="text" className={style.text}>
          {label}
        </span>
        <Link {...link} onClick={reportBiOnLinkClick} className={style.link}>
          <span data-testid="linkLabel" className={style.linkLabel}>
            {linkLabel}
          </span>
        </Link>
      </div>
    </label>
  );
};

export default React.forwardRef(Checkbox);
